<template>
  <draggable
    class="dragArea"
    v-bind="dragOptions"
    tag="ul"
    v-model="list"
    item-key="taskId"
    filter=".forbid"
    :move="onMove"
    @add="onAdd"
    @remove="onRemove"
    @update="onUpdate"
  >
    <template #item="{ element }">
      <li v-if="copyData.expand != false" class="task-node" v-show="showComplete(element)">
        <XTaskNode :data="element" @onMenuClick="onMenuClick" />
        <XTaskItem style="margin-left: 16px" :data="element" :completeMode='completeMode'/>
      </li>
    </template>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";
import XTaskNode from "@/components/XTaskNode";
// import XTabEdit from "@/components/XTabEdit"
import { taskUpdate } from "@/common/api";
import { dayjs } from "element-plus";

let moveTaskData = {};

export default {
  name: "XTaskItem",
  components: { draggable, XTaskNode },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    completeMode: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      copyData: this.data,

      changeData: {},
      list: [],
    };
  },
  mounted() {
    this.list = [...this.data.list];
  },
  computed: {
    isOpen() {
      let open = this.$store.state.openTask[this.data.projectIdStr];
      return open != null ? open : true;
    },
    dragOptions() {
      return {
        // animation: 250,
        group: "task",
        ghostClass: "drag-ghost",
      };
    },
  },
  methods: {
    showComplete(item) {

      if (this.completeMode) {
        return true
      }

      if (item.taskType == 2 && this.todayPercent(item) == 100) {
        return false
      }

      if (item.completeTime > 0 || item.giveUp) {
        return false
      }

      return true
    },
    todayPercent(task) {
      if (task.taskType == 2 && task?.habitDTO?.habitDayNum) {
        let percent =
          (this.todayNum(task) * 100) / task?.habitDTO?.habitDayNum || 0;
        if (percent > 100) {
          percent = 100;
        }
        return percent;
      } else {
        return 0;
      }
    },
    todayNum(task) {
      const date = dayjs().format("YYYYMMDD");
      let array = [];
      let completeNum = 0;
      if (task.taskType == 2 && task.clockedHistoryDTOList) {
        array = task.clockedHistoryDTOList.filter((item) => {
          return dayjs(item.time).format("YYYYMMDD") == date;
        });
        completeNum = array.reduce((pre, item) => {
          return pre + item.completeNum;
        }, 0);
      }
      return completeNum;
    },
    onMenuClick(data, index) {
      this.changeData = {};
      if (index == 0) {
        data.isCompleted = true;
      } else if (index == 1) {
        data.isInvalid = true;
      } else if (index == 2) {
        data.date = "顺延";
      } else if (index == 3) {
        this.$Modal.confirm({
          title: "删除",
          content: `删除任务会删除其下所有任务，确认删除任务（${data.title}）？`,
          onOk: () => {
            this.copyData.list.forEach((element, index, array) => {
              if (element.id == data.id) {
                array.splice(index, 1);
              }
            });
          },
        });
      }
    },
    dataCopy(data) {
      let copyData = Object.assign({}, data);
      copyData.title = copyData.title + " 副本";
      copyData.id = copyData.id + "-0";
      if (copyData.list && copyData.list.length > 0) {
        let list = [];
        copyData.list.forEach((element) => {
          list.push(this.dataCopy(element));
        });
        copyData.list = list;
      }
      return copyData;
    },
    onMove(e) {
      moveTaskData = e.draggedContext.element;
      return true;
    },
    onAdd() {
      if (this.data.key) {
        // moveTaskData.projectIdStr = null;
        if (this.data.key == "top") {
          moveTaskData.isTop = true;
          moveTaskData.parentTaskId = null;
          moveTaskData.parentTaskIdStr = null;
          moveTaskData.giveUp = false;
          moveTaskData.completeTime = 0;
          // this.data.level && (moveTaskData.level = this.data.level);
          if (this.data.level !== undefined && this.data.level !== null) {
            moveTaskData.level = this.data.level;
          }
        } else if (this.data.key == "normal") {
          moveTaskData.isTop = false;
          moveTaskData.parentTaskId = null;
          moveTaskData.parentTaskIdStr = null;
          moveTaskData.completeTime = 0;
          moveTaskData.giveUp = false;
          console.log(this.data)
          // this.data.level && (moveTaskData.level = this.data.level);
          if (this.data.level !== undefined && this.data.level !== null) {
            moveTaskData.level = this.data.level;
          }
        } else if (this.data.key == "complete") {
          // moveTaskData.parentTaskId = null;
          moveTaskData.completeTime = Date.now();
          if (!moveTaskData.originTime) {
            moveTaskData.originTime = moveTaskData.startTime;
          }
          // this.data.level && (moveTaskData.level = this.data.level);
          if (this.data.level !== undefined && this.data.level !== null) {
            moveTaskData.level = this.data.level;
          }
        } else if (this.data.key == "group") {
          moveTaskData.isTop = false;
          moveTaskData.parentTaskId = null;
          moveTaskData.parentTaskIdStr = null;
          moveTaskData.completeTime = 0;
          moveTaskData.giveUp = false;
          moveTaskData.groupIdStr = this.data.groupIdStr;
          // this.data.level && (moveTaskData.level = this.data.level);
          if (this.data.level !== undefined && this.data.level !== null) {
            moveTaskData.level = this.data.level;
          }
        } else if (this.data.key == "date") {
          moveTaskData.isTop = false;
          moveTaskData.parentTaskId = null;
          moveTaskData.parentTaskIdStr = null;
          moveTaskData.completeTime = 0;
          moveTaskData.giveUp = false;
          moveTaskData.startTime = this.data.time;
          moveTaskData.originTime = this.data.time;
          moveTaskData.endTime = 0;
          // this.data.level && (moveTaskData.level = this.data.level);
          if (this.data.level !== undefined && this.data.level !== null) {
            moveTaskData.level = this.data.level;
          }
        } else if (this.data.key == "level") {
          moveTaskData.isTop = false;
          moveTaskData.parentTaskId = null;
          moveTaskData.parentTaskIdStr = null;
          moveTaskData.completeTime = 0;
          moveTaskData.giveUp = false;
          // moveTaskData.level = this.data.level;
          if (this.data.level !== undefined && this.data.level !== null) {
            moveTaskData.level = this.data.level;
          }
        } else if (this.data.key == "tag") {
          moveTaskData.isTop = false;
          moveTaskData.parentTaskId = null;
          moveTaskData.parentTaskIdStr = null;
          moveTaskData.completeTime = 0;
          moveTaskData.giveUp = false;
          if (this.data.tagIdStr) {
            if (moveTaskData.tagList) {
              let index = moveTaskData.tagList.indexOf(this.data.tagIdStr);
              if (index > -1) {
                moveTaskData.tagList.splice(index, 1);
              }
              moveTaskData.tagList = [
                this.data.tagIdStr,
                ...moveTaskData.tagList,
              ];
            } else {
              moveTaskData.tagList = [this.data.tagIdStr];
            }
          } else {
            moveTaskData.tagList = null;
          }
          // this.data.level && (moveTaskData.level = this.data.level);
          if (this.data.level !== undefined && this.data.level !== null) {
            moveTaskData.level = this.data.level;
          }
        } else if (this.data.key == "address") {
          moveTaskData.isTop = false;
          moveTaskData.parentTaskId = null;
          moveTaskData.parentTaskIdStr = null;
          moveTaskData.completeTime = 0;
          moveTaskData.giveUp = false;
          moveTaskData.addressIdStr = this.data.addressIdStr;
          // this.data.level && (moveTaskData.level = this.data.level);
          if (this.data.level !== undefined && this.data.level !== null) {
            moveTaskData.level = this.data.level;
          }
        }
      } else {
        moveTaskData.parentTaskId = this.data.taskIdStr;
        moveTaskData.parentTaskIdStr = this.data.taskIdStr;
        if (this.data.completeTime > 0) {
          moveTaskData.giveUp = this.data.giveUp;
          moveTaskData.completeTime = Date.now();
          if (!moveTaskData.originTime) {
            moveTaskData.originTime = moveTaskData.startTime;
          }
        } else {
          moveTaskData.completeTime = 0;
          moveTaskData.giveUp = false;
          moveTaskData.isTop = this.data.isTop;
        }
        // this.data.level && (moveTaskData.level = this.data.level);
        if (this.data.level !== undefined && this.data.level !== null) {
            moveTaskData.level = this.data.level;
          }
      }
      this.sortUpdate();
    },
    onRemove() {
      this.copyData.list = this.list;
    },
    onUpdate() {
      if (!moveTaskData) return;
      this.sortUpdate();
    },
    sortUpdate() {
      this.copyData.list = this.list;
      let list = this.list;
      let index = list.indexOf(moveTaskData);
      if (index == -1) return;
      if (index == 0) {
        if (list.length > 1) {
          moveTaskData.positionWeight = list[1].positionWeight + 1;
        }
      } else if (index == list.length - 1) {
        moveTaskData.positionWeight =
          list[this.data.list.length - 2].positionWeight - 1;
      } else {
        moveTaskData.positionWeight =
          (list[index - 1].positionWeight + list[index + 1].positionWeight) / 2;
      }
      taskUpdate(moveTaskData);
    },
  },
  watch: {
    data() {
      this.copyData = this.data;
      this.list = [...this.data.list];
    },
    "data.list"() {
      this.list = [...this.data.list];
    },
    "data.list.length"() {
      this.list = [...this.data.list];
    },
  },
};
</script>

<style lang="less" scoped>
// .drag-ghost {
//   border: 1px solid #4772Fa;
// }
.dragArea {
  // padding-top: 5px;
  border-radius: 8px;
}
.task-node {
  background-color: var(--vp-c-bg);
  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
</style>
