<template>
  <div class="task" direction="vertical">
    <div class="task-header">
      <Dropdown :trigger="hasSubProject ? 'click' : 'custom'">
        <a href="javascript:void(0)" style="display: flex; align-items: center">
          <Image
            v-if="project.iconUrl"
            :src="project.iconUrl"
            width="24px"
            alt="icon"
          >
            <template #placeholder>
              <Icon type="ios-image-outline" size="24" color="#ccc" />
            </template>
            <template #error>
              <Icon type="ios-image-outline" size="24" color="#ccc" />
            </template>
          </Image>
          <Text ellipsis class="project-name">{{ project.name }}</Text>
          <XIcon
            v-if="hasSubProject"
            icon-class="arrow_right"
            style="transform: rotate(90deg); font-size: 20px"
          />
        </a>
        <template #list>
          <DropdownMenu class="dropdown-list">
            <DropdownItem
              v-for="item in project.list"
              :key="item.projectIdStr"
              @click="onProjectClick(item)"
            >
              {{ item.name }}
            </DropdownItem>
          </DropdownMenu>
        </template>
      </Dropdown>
      <Space>
        <!-- <Checkbox v-model="detailMode" @on-change="onDetailModeChange">详细</Checkbox>
        <Checkbox v-model="completeMode" @on-change="onCompleteModeChange">已完成</Checkbox> -->
        <Dropdown trigger="click" title="任务排序">
          <XIcon icon-class="sort" style="font-size: 18px" />
          <template #list>
            <DropdownMenu class="dropdown-list">
              <DropdownItem
                v-for="item in sortList"
                :key="item.id"
                :selected="sortId == item.id"
                @click="onSortClick(item)"
              >
                {{
                  (isAllProject || isDateProject) && item.id == 1
                    ? "清单排序"
                    : item.title
                }}
              </DropdownItem>
            </DropdownMenu>
          </template>
        </Dropdown>
        <Dropdown trigger="click" title="任务筛选">
          <!-- <XIcon icon-class="more-col" style="font-size: 16px;"/> -->
          <Icon type="md-more" size="22" class="more-icon" />
          <template #list>
            <DropdownMenu class="dropdown-list">
              <DropdownItem
                v-for="item in menuList"
                :key="item.id"
                @click="onMoreClick(item)"
              >
                <div>
                  <XIcon :iconClass="item.key" style="margin-right: 8px" />
                  {{ item.title }}
                </div>
              </DropdownItem>
            </DropdownMenu>
          </template>
        </Dropdown>
        <Dropdown trigger="click" title="数据同步">
          <XIcon
            icon-class="refresh"
            :class="{ rotate: inSync }"
            style="font-size: 16px"
            @click="refreshTask"
          />
        </Dropdown>
      </Space>
    </div>
    <div :class="{ 'input-bg': true, focus: ifocus }">
      <div style="display: flex; align-items: center">
        <XIcon
          style="margin-left: 6px"
          :icon-class="prefixIcon"
          size="16"
          @mousedown.stop="prefixIconClick"
        />
        <Input
          ref="input"
          style="font-size: 14px"
          v-model="inputData.input"
          :class="ifocus ? 'focus' : ''"
          :placeholder="`添加${prefixTitle}，按回车创建，Tab键添加描述`"
          :border="false"
          :autosize="ifocus"
          type="textarea"
          @on-focus="onInputFocus"
          @on-enter="onInputEnter"
          @on-blur="ifocus = false"
          @on-keydown.tab="onInputKeydown"
          @on-change="debouncedHandleInput"
        >
        </Input>
        <div v-html="formattedText" class="title-mask"></div>
      </div>
      <Progress
        :percent="addLoading"
        status="active"
        :stroke-width="1"
        :hide-info="true"
      />
      <Space
        v-if="ifocus"
        style="
          margin-top: 10px;
          padding: 0px 10px;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
        "
        @mousedown.stop="$event.preventDefault()"
      >
        <Button
          type="info"
          size="small"
          class="horizontal-center tag-button"
          @mousedown.stop="onAttachClick"
        >
          <template v-if="inputData.attach.startTime">
            <XIcon iconClass="schedule" style="color: #3d87da" />
            <span style="margin: 0px 3px; font-size: 12px">{{
              attachTitle
            }}</span>
            <XIcon
              v-if="
                inputData.attach.notices && inputData.attach.notices.length > 0
              "
              size="14"
              iconClass="task_alert"
            />
            <XIcon
              v-if="
                inputData.attach.repeat &&
                Object.keys(inputData.attach.repeat).length > 0
              "
              size="14"
              iconClass="task_cyclic"
            />
            <Icon type="md-close" @mousedown.stop="onAttachClear" />
          </template>
          <template v-else>
            <XIcon iconClass="schedule" style="color: #979ea7" />
          </template>
        </Button>
        <Dropdown trigger="click">
          <Button
            size="small"
            type="info"
            class="horizontal-center tag-button"
            @mousedown.stop="onLevelClick"
          >
            <XIcon :iconClass="inputData.level.icon" />
            <!-- <Icon type="md-flag" /> -->
          </Button>
          <template #list>
            <DropdownMenu>
              <DropdownItem
                v-for="item in levelList"
                :key="item.icon"
                @mousedown.stop="onLevelSelected($event, item)"
              >
                <XIcon :iconClass="item.icon" style="margin-right: 4px" />
                {{ item.title }}
              </DropdownItem>
            </DropdownMenu>
          </template>
        </Dropdown>
        <Dropdown trigger="click">
          <Button
            size="small"
            type="info"
            class="horizontal-center tag-button"
            @mousedown.stop="onInputTagClick"
          >
            <!-- <XIcon iconClass="tag" size="14" /> -->
            <Icon type="ios-pricetags" />
            <Text
              v-if="inputData.tag.length"
              ellipsis
              style="
                max-width: 80px;
                margin-left: 3px;
                font-size: 12px;
                color: #333;
              "
            >
              {{ inputData.tag.map((item) => item.tagName).join(",") }}
            </Text>
          </Button>
          <template #list>
            <XTagMenu
              :onSelect="onTagBoxClick"
              :dataList="$store.state.tagList.list"
              :selectedList="inputData.tag.map((item) => item.tagIdStr)"
            />
          </template>
        </Dropdown>
        <Poptip placement="bottom" v-model="showPoptip" class="fixed">
          <Button
            size="small"
            type="info"
            class="horizontal-center tag-button"
            @click.stop="onInputProjectClick"
          >
            <span style="font-size: 12px">
              <template v-if="inputData.project.name">{{
                inputData.project.name
              }}</template>
              <template
                v-else-if="!isAllProject && !isDateProject && project.name"
                >{{ project.name }}</template
              >
              <template v-else>收集箱</template>
              -{{ inputData.group.name || "未分组" }}
            </span>
          </Button>
          <template #content>
            <div style="display: flex">
              <Tree
                style="min-width: 150px"
                :data="projectList"
                children-key="list"
                @on-select-change="onTreeSelect"
                :render="renderContent"
              ></Tree>
              <CellGroup
                style="border-left: 1px solid #f5f5f5; min-width: 150px"
              >
                <Cell
                  v-for="(group, index) in inputGroupList"
                  :key="index"
                  :title="group.name"
                  :selected="inputData.group.groupIdStr == group.groupIdStr"
                  @click="onGroupClick(group)"
                />
              </CellGroup>
            </div>
          </template>
        </Poptip>
        <!-- <Poptip placement="bottom" :width="220" trigger="click" class="fixed">
          <Button size="small" @mousedown.stop="onInputPrformatDataojectClick">
            {{ inputData.project.name || project.name || '收件箱' }}
          </Button>
          <template #content>
            <div style="display: flex;">
              <Tree :data="projectList" children-key="list" @on-select-change="onTreeSelect"></Tree>
            </div>
          </template>
        </Poptip> -->
      </Space>
    </div>

    <div class="task-content">
      <ul style="width: 100%">
        <XTaskGroup
          v-for="(item, index) in formatData"
          :key="item + index"
          :data="item"
          :completeMode='completeMode'
          v-show="formatData.length"
        />
      </ul>
      <div
        style="padding: 0 38px 0 38px"
        v-show="!formatData.length && loadTask"
      >
        <Skeleton :loading="true" animated class="skeleton" />
        <Skeleton
          :loading="true"
          animated
          class="skeleton"
          style="margin-top: 15%"
        />
        <Skeleton
          :loading="true"
          animated
          class="skeleton"
          style="margin-top: 15%"
        />
      </div>
      <div v-show="!formatData.length && !loadTask" class="content-empty">
        <XIcon iconClass="no_task" style="font-size: 150px" />
        <p class="title">创建你的第一条任务</p>
        <p class="sub-title">点击顶部输入框进行添加，按回车创建哦</p>
      </div>
    </div>
    <Dropdown
      ref="menu"
      class="menu"
      :style="{ left: menuOptions.x, top: menuOptions.y }"
      trigger="click"
    >
      <template #list>
        <DropdownMenu class="dropdown-list">
          <DropdownItem @click="onMenuClick('top')">
            <XIcon iconClass="more_top" style="margin-right: 8px" />
            <Text style="width: 103px" ellipsis>{{
              editData.isTop ? "取消置顶" : "置顶"
            }}</Text>
          </DropdownItem>
          <DropdownItem divided @click="onMenuClick('date')">
            <XIcon iconClass="more_date" style="margin-right: 8px" />
            <Text style="width: 103px" ellipsis>日期&重复</Text>
          </DropdownItem>
          <Dropdown placement="right-start">
            <DropdownItem>
              <XIcon iconClass="more_level" style="margin-right: 8px" />
              <Text style="width: 103px" ellipsis>优先级</Text>
              <Icon type="ios-arrow-forward"></Icon>
            </DropdownItem>
            <template #list>
              <DropdownMenu class="dropdown-list">
                <DropdownItem
                  v-for="item in levelList"
                  :key="item.icon"
                  @click="onMenuClick(item.icon)"
                >
                  <XIcon :iconClass="item.icon" style="margin-right: 8px" />
                  <span
                    :class="{ 'primary-color': editData.level == item.level }"
                    >{{ item.title }}</span
                  >
                </DropdownItem>
              </DropdownMenu>
            </template>
          </Dropdown>
          <Dropdown trigger="hover" placement="right-start">
            <DropdownItem>
              <XIcon iconClass="more_tag" style="margin-right: 8px" />
              <Text style="width: 103px" ellipsis>标签</Text>
              <Icon type="ios-arrow-forward"></Icon>
            </DropdownItem>
            <template #list>
              <XTagMenu
                :menuWidth="168"
                :onSelect="onTagClick"
                :dataList="$store.state.tagList.list"
                :selectedList="editData.tagList"
              />
            </template>
          </Dropdown>
          <DropdownItem
            v-if="editData.taskType != 2"
            @click="onMenuClick('task')"
          >
            <XIcon iconClass="more_sub" style="margin-right: 8px" />
            <Text style="width: 103px" ellipsis>添加子任务</Text>
          </DropdownItem>
          <DropdownItem @click="onMenuClick('location')">
            <XIcon iconClass="more_local" style="margin-right: 8px" />
            <Text style="width: 103px" ellipsis>添加地点</Text>
          </DropdownItem>
          <Dropdown placement="right-start">
            <DropdownItem>
              <XIcon iconClass="more_move" style="margin-right: 8px" />
              <Text style="width: 103px" ellipsis>移动到</Text>
              <Icon type="ios-arrow-forward"></Icon>
            </DropdownItem>
            <template #list>
              <DropdownMenu style="width: 168px" class="dropdown-list">
                <DropdownItem
                  v-for="(group, index) in inputGroupList"
                  class="move-list"
                  :key="group.groupIdStr + index"
                  @click="onGroupItemClick(group)"
                >
                  <Text
                    v-if="
                      editData.groupIdStr == group.groupIdStr ||
                      (group.projectIdStr == group.groupIdStr &&
                        !editData.groupIdStr)
                    "
                    style="color: #3d87da"
                    :title="group.name"
                    ellipsis
                  >
                    {{ group.name }}
                  </Text>
                  <Text v-else :title="group.name" ellipsis>
                    {{ group.name }}
                  </Text>
                </DropdownItem>
                <XProjectMenu
                  :projectId="editData.projectIdStr"
                  :dataList="projectList"
                  :onSelect="onMenuMove"
                />
              </DropdownMenu>
            </template>
          </Dropdown>
          <DropdownItem divided @click="onMenuClick('copy')">
            <XIcon iconClass="more_copy" style="margin-right: 8px" />
            <Text style="width: 103px" ellipsis>复制</Text>
          </DropdownItem>
          <DropdownItem
            v-if="editData.taskType != 2"
            @click="onMenuClick('type')"
          >
            <XIcon iconClass="more_note" style="margin-right: 8px" />
            <Text style="width: 103px" ellipsis>{{
              editData.taskType == 0 ? "转为笔记" : "转为待办"
            }}</Text>
          </DropdownItem>
          <DropdownItem divided @click="onMenuClick('giveup')">
            <XIcon iconClass="more_giveup" style="margin-right: 8px" />
            <Text style="width: 103px" ellipsis v-if="editData.taskType != 2">{{
              editData.giveUp ? "取消放弃" : "放弃"
            }}</Text>
            <Text style="width: 103px" ellipsis v-else>{{
              editData.endTime > 0 ? "重启习惯" : "结束习惯"
            }}</Text>
          </DropdownItem>
          <DropdownItem @click="onMenuClick('delete')">
            <XIcon iconClass="more_delete" style="margin-right: 8px" />
            <Text style="width: 103px" ellipsis>删除</Text>
          </DropdownItem>
        </DropdownMenu>
      </template>
    </Dropdown>
    <XAttachModal
      :show="showModal"
      :data="inputData.attach"
      @onModalChanged="onModalChanged"
      @onModalOk="onModalOk"
      @onModalCancel="onModalCancel"
    />

    <Modal
      :mask="true"
      :width="440"
      v-model="showFiltra"
      @on-ok="onFiltraModalOk"
      draggable
      sticky
      scrollable
      title="任务筛选"
    >
      <XOptionModal
        :showFiltra="showFiltra"
        :data="filtraData"
        ref="filterRef"
      />
    </Modal>

    <Modal
      class="drag-modal"
      draggable
      sticky
      scrollable
      :mask="true"
      :width="420"
      v-model="showGroupModal"
      title="新建分组"
      @on-ok="onGroupModalOk"
    >
      <Input
        style="margin-bottom: 12px"
        :autofocus="true"
        v-model="groupInput"
        placeholder="请输入分组名称"
      />
      <span style="font-size: 12px; color: gray"
        >创建后将自动切换至「分组排序」</span
      >
    </Modal>
    <XLocationModal v-model="showMap" @onModalOk="onMapModalOk" />
    <context-menu v-model:show="groupMenuShow" :options="groupMenuOptions">
      <context-menu-item
        v-if="groupEditData.groupIdStr != groupEditData.projectIdStr"
        label="重命名"
        @click="onGroupMenuClick('edit')"
      />
      <context-menu-item label="将任务移至" @click="onGroupMenuClick('move')" />
      <context-menu-item label="分组排序" @click="onGroupMenuClick('sort')" />
      <context-menu-item
        v-if="groupEditData.groupIdStr != groupEditData.projectIdStr"
        label="删除分组"
        @click="onGroupMenuClick('delete')"
      />
    </context-menu>
    <Modal
      class="drag-modal"
      draggable
      sticky
      scrollable
      :mask="true"
      :width="420"
      v-model="showGroupEditModal"
      title="编辑分组"
      @on-ok="onGroupEditModalOk"
    >
      <Input
        style="margin-bottom: 12px"
        :autofocus="true"
        v-model="groupEditInput"
        placeholder="请输入分组名称"
      />
    </Modal>
    <Modal
      class="drag-modal"
      draggable
      sticky
      scrollable
      :mask="true"
      :width="420"
      v-model="showGroupMoveModal"
      title="移动任务"
      @on-ok="onGroupMoveModalOk"
    >
      <p style="font-size: 12px; color: gray; margin-bottom: 12px">
        为当前分组下的任务和笔记指定一个新的分组：
      </p>
      <Select v-model="groupMoveModel" style="width: 380px">
        <template v-for="item in groupList" :key="item.groupIdStr">
          <Option
            v-if="item.groupIdStr != groupEditData.groupIdStr"
            :value="item.groupIdStr"
            >{{ item.name }}</Option
          >
        </template>
      </Select>
    </Modal>
    <Modal
      class="drag-modal"
      draggable
      sticky
      scrollable
      :mask="true"
      :width="420"
      v-model="showGroupSortModal"
      title="分组排序"
      @on-ok="onGroupSortModalOk"
    >
      <p class="drag-group-sort-title">点击分组并拖动排序</p>
      <draggable
        v-bind="dragOptions"
        tag="ul"
        group="group"
        v-model="groupSortList"
        item-key="groupId"
      >
        <template #item="{ element }">
          <li class="drag-group-sort">
            <Icon
              type="ios-menu"
              style="font-size: 20px; margin-right: 12px"
            />{{ element.name }}
          </li>
        </template>
      </draggable>
    </Modal>
    <Modal
      class="drag-modal"
      draggable
      sticky
      scrollable
      :mask="true"
      :width="420"
      v-model="showGroupDeleteModal"
      title="删除分组"
      @on-ok="onGroupDeleteModalOk"
    >
      <p style="font-size: 12px; color: gray; margin-bottom: 12px">
        确定删除当前分组吗？你可以为此分组下的任务和笔记指定一个新的分组：
      </p>
      <Select v-model="groupMoveModel" style="width: 380px">
        <template v-for="item in groupList" :key="item.groupIdStr">
          <Option
            v-if="item.groupIdStr != groupEditData.groupIdStr"
            :value="item.groupIdStr"
            >{{ item.name }}</Option
          >
        </template>
      </Select>
    </Modal>
  </div>
</template>

<script>
import XTaskGroup from "@/components/XTaskGroup";
import XProjectMenu from "@/components/XProjectMenu";
import XTagMenu from "@/components/XTagMenu";
import XIcon from "@/components/XIcon";
import XAttachModal from "@/components/XAttachModal";
import XOptionModal from "@/components/XOptionModal";
import XLocationModal from "@/components/XLocationModal";
import draggable from "vuedraggable";
import {
  taskAdd,
  taskUpdate,
  taskDelete,
  taskList,
  groupList,
  groupAdd,
  noticeAdd,
  noticeDelete,
  repeatAdd,
  repeatDelete,
  noticeDetail,
  repeatDetail,
  groupUpdate,
  groupDelete,
  habitAdd,
  noticeArrive,
  timeRecogntion,
  projectList,
} from "@/common/api";
import { formatTime } from "@/common/util";
import { Input } from "view-ui-plus";
import { syncData } from "@/common/syncUntil";
import { debounce } from "lodash";
import { dayjs } from "element-plus";

const levelList = [
  {
    title: "高优先级",
    icon: "flag_high",
    level: 3,
  },
  {
    title: "中优先级",
    icon: "flag_middle",
    level: 2,
  },
  {
    title: "低优先级",
    icon: "flag_low",
    level: 1,
  },
  {
    title: "无优先级",
    icon: "flag_normal",
    level: 0,
  },
];

export default {
  components: {
    XTaskGroup,
    XIcon,
    XProjectMenu,
    XTagMenu,
    XAttachModal,
    XLocationModal,
    XOptionModal,
    draggable,
    Input,
  },
  props: {
    subTitle: {
      type: String,
      default: "任务和笔记的临时存放地。",
    },
  },
  provide() {
    return {
      onMenuClick: (event, data) => {
        if (event == "Tab") {
          this.editData = data;
          this.onMenuClick(event);
        } else {
          this.detailMenu = false;
          this.showTaskMenu(event, data);
        }
      },
      onGroupMenuEvent: (event, data) => this.showGroupMenu(event, data),
    };
  },
  data() {
    return {
      addLoading: 0,
      formattedRes: "",
      formattedText: "",
      loadTask: false,
      inSync: false,
      showPoptip: false,
      detailMenu: false,
      showMap: false,
      location: {},
      debouncedHandleInput: null,
      showModal: false,
      ifocus: false,
      recognitionTime: (localStorage.getItem("recognitionTime") || 0) == 0 ? true : false,
      collectionCompletedTask: (localStorage.getItem("collectionCompletedTask") || 1) == 1 ? true : false,
      showFiltra: false,
      filtraData: {
        checkTypeVal: 1, //筛选类型 1.所有 2.指派给我 3.自定义
        type: [], //
        todo: [],
        date: [],
        tag: [],
        member: [],
      },
      showGroupModal: false,
      groupInput: "",

      detailMode: false,
      subTaskMode: false,
      completeMode: false,
      sortList: [
        {
          title: "默认排序",
          id: 0,
        },
        {
          title: "分组排序", // 清单排序
          id: 1,
        },
        {
          title: "开始时间排序",
          id: 2,
        },
        {
          title: "截至时间排序",
          id: 3,
        },
        {
          title: "优先级排序",
          id: 4,
        },
        {
          title: "标签排序",
          id: 5,
        },
        {
          title: "地点排序",
          id: 6,
        },
      ],
      sortId: 0,
      // menuList: [],
      levelList,

      data: [],
      groupList: [],
      editGroupList: [],
      packageList: [],

      menuShow: false,
      menuOptions: {
        x: 500,
        y: 200,
      },
      editData: {},
      // 输入数据
      inputData: {
        type: 0,
        input: "",
        attach: {},
        level: levelList[3],
        tag: [],
        project: {},
        group: {},
      },

      groupMenuShow: false,
      groupMenuOptions: {
        zIndex: 999,
        minWidth: 230,
        x: 500,
        y: 200,
      },
      showGroupEditModal: false,
      showGroupMoveModal: false,
      showGroupSortModal: false,
      showGroupDeleteModal: false,
      groupEditData: {},
      groupEditInput: "",
      groupMoveModel: 0,
      groupSortList: [],
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "tagGroup",
        ghostClass: "drag-ghost",
      };
    },
    isAllProject() {
      return this.project.projectIdStr == "-2" + this.project.userId;
    },
    isDateProject() {
      return this.project.projectType == 1;
    },
    isNormalDefault() {
      return "1" + this.project.userId == this.project.projectIdStr;
    },
    prefixIcon() {
      if (this.inputData.type == 0) {
        return this.ifocus ? "todo_high" : "todo_normal";
      } else if (this.inputData.type == 1) {
        return this.ifocus ? "note_high" : "note_normal";
      } else if (this.inputData.type == 2) {
        return this.ifocus ? "habit_high" : "habit_normal";
      }
      return "";
    },
    prefixTitle() {
      if (this.inputData.type == 0) {
        return "任务";
      } else if (this.inputData.type == 1) {
        return "笔记";
      } else if (this.inputData.type == 2) {
        return "习惯";
      }
      return "笔记";
    },
    inputGroupList() {
      if (
        !this.inputData.project.projectIdStr ||
        this.inputData.project.projectIdStr == this.project.projectIdStr
      ) {
        return this.groupList;
      }
      return this.editGroupList;
    },
    attachTitle() {
      let showTime = formatTime(
        this.inputData.attach.startTime,
        this.inputData.attach.endTime,
        false
      );
      return showTime;
    },
    listFlag() {
      return this.$store.state.listFlag;
    },
    currentTask() {
      return this.$store.state.currentTask;
    },
    project() {
      return this.$store.state.currentProject;
    },
    projectList() {
      return this.$store.getters.normalProjectList;
    },
    hasSubProject() {
      return this.project.list && this.project.list.length > 0;
    },
    menuList() {
      let list = [
        {
          key: "filtra",
          title: "任务筛选",
        },
        {
          key: this.detailMode ? "done_hide" : "done_show",
          title: this.detailMode ? "简约模式" : "详细模式",
        },
        {
          key: this.subTaskMode ? "subTask_hide" : "subTask_show",
          title: this.subTaskMode ? "隐藏子清单任务" : "显示子清单任务",
        },
        {
          key: this.completeMode ? "eye_hide" : "eye_show",
          title: this.completeMode ? "隐藏已完成" : "显示已完成",
        },
        // {
        //   'key': 'filter',
        //   'title': '任务筛选'
        // }
      ];
      if (!this.isAllProject && !this.isDateProject) {
        list.push({
          key: "folder",
          title: "添加分组",
        });
      }
      list.push({
        key: "map",
        title: "任务地图",
      });
      // if (!this.isAllProject && !this.isDateProject && !this.isNormalDefault) {
      //   list.push({
      //     'key': 'share',
      //     'title': '共享协作'
      //   });
      // }
      return list;
    },
    formatData() {
      let showList = [];
      this.packageList.forEach((item) => {
        if (item.key == "top") {
          showList.push(item);
          if (this.sortId == 2) {
            item.list = item.list.sort((a, b) => b.startTime - a.startTime);
          } else if (this.sortId == 3) {
            item.list = item.list.sort((a, b) => b.endTime - a.endTime);
          } else {
            item.list = item.list.sort(
              (a, b) => b.positionWeight - a.positionWeight
            );
          }
        } else if (item.key == "normal") {
          if (this.sortId == 0) {
            showList.push(item);
            if (item?.list.length) {
              item.list = this.positionWeightHandle(item.list);
            }
          } else if (this.sortId == 1) {
            if (this.isAllProject || this.isDateProject) {
              let obj = {};
              item.list.forEach((task) => {
                let projectId = task.projectIdStr;
                let subGroup = obj[projectId];
                if (!subGroup) {
                  let cProject = this.$store.state.baseProjectList.find(
                    (subProject) => subProject.projectIdStr == projectId
                  );
                  subGroup = {
                    name: cProject ? cProject.name : "",
                    icon: cProject ? cProject.iconUrl : "",
                    key: "project",
                    projectId,
                    list: [task],
                  };
                  obj[projectId] = subGroup;
                } else {
                  subGroup.list.push(task);
                }
              });
              let pList = Object.values(obj);
              pList.forEach((element) => {
                element.list = element.list.sort(
                  (a, b) => b.positionWeight - a.positionWeight
                );
                showList.push(element);
              });
            } else {
              this.groupList.forEach((group) => {
                let subGroup = {
                  key: "group",
                  ...group,
                  list: [],
                };
                item.list.forEach((task) => {
                  if (
                    task.groupIdStr == group.groupIdStr ||
                    (task.groupIdStr == null &&
                      group.projectIdStr == group.groupIdStr)
                  ) {
                    subGroup.list.push(task);
                  }
                });
                showList.push(subGroup);
              });
            }
          } else if (this.sortId == 2 || this.sortId == 3) {
            let date = new Date().setHours(0, 0, 0, 0);
            let lastDate = date - 1000 * 60 * 60 * 24;
            let beforeGroup = {
              key: "date",
              time: lastDate,
              name: "今天以前",
              list: [],
            };
            let todayGroup = {
              key: "date",
              time: new Date().setHours(0, 0, 0, 0),
              name: "今天",
              list: [],
            };
            let otherGroup = {};
            let nodateGroup = {
              key: "date",
              time: 0,
              name: "没有日期",
              list: [],
            };
            item.list.forEach((task) => {
              let originTime = this.sortId == 2 ? task.startTime : task.endTime;
              if (task.startTime > 0 || task.endTime > 0) {
                const todayTime = new Date().setHours(0, 0, 0, 0);
                if (originTime < todayTime) {
                  beforeGroup.list.push(task);
                } else {
                  const startTime = new Date(originTime).setHours(0, 0, 0, 0);
                  if (startTime == todayTime) {
                    todayGroup.list.push(task);
                  } else {
                    const timeKey = new Date(originTime).format("MM月dd日");
                    let realGroup = otherGroup[timeKey];
                    if (realGroup) {
                      realGroup.list.push(task);
                    } else {
                      realGroup = {
                        key: "date",
                        time: new Date(originTime).setHours(0, 0, 0, 0),
                        name: timeKey,
                        list: [task],
                      };
                      otherGroup[timeKey] = realGroup;
                    }
                  }
                }
              } else {
                nodateGroup.list.push(task);
              }
            });
            let sortList = Object.values(otherGroup);
            sortList.sort((a, b) => a.time - b.time);
            let allList = [beforeGroup, todayGroup, ...sortList, nodateGroup];
            allList.forEach((element) => {
              if (element.list.length > 0) {
                if (this.sortId == 2) {
                  element.list = element.list.sort(
                    (a, b) => a.startTime - b.startTime
                  );
                } else {
                  element.list = element.list.sort((a, b) => {
                    let aTime = a.endTime > 0 ? a.endTime : a.startTime;
                    let bTime = b.endTime > 0 ? b.endTime : b.startTime;
                    return aTime - bTime;
                  });
                }
                showList.push(element);
              }
            });
          } else if (this.sortId == 4) {
            let highGroup = {
              name: "高优先级",
              key: "level",
              level: 3,
              list: [],
            };
            let middleGroup = {
              name: "中优先级",
              key: "level",
              level: 2,
              list: [],
            };
            let lowGroup = {
              name: "低优先级",
              key: "level",
              level: 1,
              list: [],
            };
            let noGroup = {
              name: "无优先级",
              key: "level",
              level: 0,
              list: [],
            };
            item.list.forEach((task) => {
              if (task.level == 0) {
                noGroup.list.push(task);
              } else if (task.level == 1) {
                lowGroup.list.push(task);
              } else if (task.level == 2) {
                middleGroup.list.push(task);
              } else if (task.level == 3) {
                highGroup.list.push(task);
              }
            });
            let allList = [highGroup, middleGroup, lowGroup, noGroup];
            allList.forEach((element) => {
              if (element.list.length > 0) {
                showList.push(element);
              }
            });
          } else if (this.sortId == 5) {
            let otherGroup = {};
            let noGroup = {
              name: "无标签",
              key: "tag",
              list: [],
            };
            item.list.forEach((task) => {
              if (task.tagList && task.tagList.length > 0) {
                let tagId = task.tagList[0];
                let realGroup = otherGroup[tagId];
                if (realGroup) {
                  realGroup.list.push(task);
                } else {
                  let tag = this.$store.state.baseTagList.find(
                    (item) => item.tagIdStr == tagId
                  );
                  if (tag) {
                    realGroup = {
                      key: "tag",
                      color: tag.color,
                      tagId: tag.tagIdStr,
                      name: tag.tagName,
                      list: [task],
                    };
                    otherGroup[tagId] = realGroup;
                  }
                }
              } else {
                noGroup.list.push(task);
              }
            });
            let allList = [...Object.values(otherGroup), noGroup];
            allList.forEach((element) => {
              if (element.list.length > 0) {
                showList.push(element);
              }
            });
          } else if (this.sortId == 6) {
            let otherGroup = {};
            let noGroup = {
              key: "address",
              name: "无地点",
              list: [],
            };
            item.list.forEach((task) => {
              if (task.addressIdStr) {
                let realGroup = otherGroup[task.addressIdStr];
                if (realGroup) {
                  realGroup.list.push(task);
                } else {
                  let address = this.$store.state.addressList.find(
                    (item) => item.addressIdStr == item.addressIdStr
                  );
                  if (address) {
                    realGroup = {
                      key: "address",
                      addressId: address.addressIdStr,
                      name: address.poiAddress,
                      list: [task],
                    };
                    otherGroup[task.addressIdStr] = realGroup;
                  } else {
                    noGroup.list.push(task);
                  }
                  otherGroup[task.addressIdStr] = realGroup;
                }
              } else {
                noGroup.list.push(task);
              }
            });
            let allList = [...Object.values(otherGroup), noGroup];
            allList.forEach((element) => {
              if (element.list.length > 0) {
                showList.push(element);
              }
            });
          }
        } else if (item.key == "complete") {
          if (this.completeMode) {
            showList.push(item);
            item.list = item.list.sort(
              (a, b) => b.completeTime - a.completeTime
            );
          }
        }
      });
      return showList;
    },
  },
  mounted() {
    // let storeState = this.$store.state;
    // this.detailMode = storeState.taskDetailMode;
    // this.completeMode = storeState.taskCompleteMode;
    // this.sortId = storeState.taskSortId;

    if (this.recognitionTime) {
      this.debouncedHandleInput = debounce(this.onInputChange, 300); // 300ms 防抖延迟
    }
    this.loadData();
    this.setStartTime();
  },
  methods: {
    positionWeightHandle(array) {
      array.sort((a, b) => b.positionWeight - a.positionWeight);
      array.map((item) => {
        if (item?.list.length) {
          this.positionWeightHandle(item.list);
        }
      });
      return array;
    },
    renderContent(h, { data }) {
      return h("span", [
        h(
          "span",
          {
            style: {
              display: "flex",
              alignItems: "center",
            },
          },
          [
            h("img", {
              src: data.iconUrl,
              style: {
                width: "14px", // 调整图标的大小
                height: "14px", // 调整图标的大小
                marginRight: "8px",
              },
            }),
            h("span", data.title),
          ]
        ),
      ]);
    },
    async refreshTask() {
      this.inSync = true;
      await syncData();
      this.loadData();
      this.$store.commit("updateProjectData", Date.now());
      this.inSync = false;
      this.$Message.success("同步成功");
    },
    prefixIconClick(e) {
      e.preventDefault();
      this.inputData.type = ++this.inputData.type % 3;
      if (this.inputData.type == 2) {
        this.inputData.attach = {
          startTime: new Date().setHours(0, 0, 0, 0),
          repeat: {
            endMode: 0,
            fixedDays: null,
            interval: 1,
            jumpHoliday: false,
            jumpWeekend: false,
            monthDays: null,
            monthLast: false,
            msg: "每天",
            repeatEndDate: 0,
            repeatEndTimes: 0,
            repeatMode: 1,
            self: false,
            startMode: 0,
            weekdays: null,
            yearDay: 0,
            yearMonth: 0,
          },
          habit: {
            habitUnit: "次", //打卡单位
            autoShowLog: false, //打卡后弹出打卡心得
            habitType: 0, //打卡类型 0自动打卡 点击一下自动新增打卡  1手动打卡,打卡后弹出对话框,自定义打卡量 2全部完成 打卡完成全部的打卡量
            habitDayNum: 1, //每天需要完成的打卡量
            habitOnceNum: 1, //每次打卡新增量 比如每天喝2000 一次打卡+200
            autoAbsorbed: false, //打卡后自动开始专注(网页端暂无用)
          },
          attachType: 2,
        };
      } else {
        this.inputData.attach = {};
      }
    },
    async loadData() {
      this.loadTask = true;
      let projectId = this.$route.params.project;
      this.detailMode =
        localStorage.getItem(projectId + "-" + "detailMode") == "1";
      this.completeMode =
        localStorage.getItem(projectId + "-" + "completeMode") == "1";
      this.subTaskMode =
        localStorage.getItem(projectId + "-" + "subTaskMode") == "1";
      this.sortId =
        localStorage.getItem(projectId + "-" + "sortId") ||
        (this.isAllProject || this.isDateProject ? 1 : 0);
      this.$store.commit("updateTaskDetailMode", this.detailMode);
      this.$store.commit("updateProjectCount", {
        key: "update",
        value: true,
      });
      try {
        this.filtraData = JSON.parse(
          localStorage.getItem(projectId + "-" + "filtraData") ??
            '{"checkTypeVal":1,"type":[],"todo":[],"date":[],"tag":[],"member":[]}'
        );
      } catch (error) {
        console.log(error);
      }

      if (projectId && projectId.length > 0) {
        await groupList(projectId).then((res) => {
          if (res) {
            this.groupList = res.sort(
              (a, b) => b.positionWeight - a.positionWeight
            );
          }
          this.loadTask = false;
        });

        if (this.subTaskMode == "1") {
          const tempData = await projectList();
          const allChildrenIds = this.getAllChildren(projectId, tempData);
          this.data = [];
          for (const item of allChildrenIds) {
            const res = await taskList(item);
            if (res?.length) {
              this.data.push(...res);
            }
          }
          this.$store.commit("updateTasks", this.data);
          this.packageData();
          this.loadTask = false;
        } else {
          await taskList(projectId)
            .then((res) => {
              if (res) {
                this.$store.commit("updateTasks", res);
                this.data = res;
                this.packageData();
                this.loadTask = false;
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
      this.loadTask = false;
    },
    getAllChildren(id, data) {
      // 查找当前 id 对应的所有子级
      const children = data.filter((item) => item.parentProjectIdStr === id);

      // 获取当前 id 的子级的 id
      let childrenIds = children.map((child) => child.projectIdStr);

      // 对每个子级，递归查找其子级，合并所有子级的 id
      children.forEach((child) => {
        childrenIds = childrenIds.concat(
          this.getAllChildren(child.projectIdStr, data)
        ); // 递归
      });

      // 在结果数组的开头加入父级 id
      return [...new Set([id, ...childrenIds])];
    },
    packageData() {
      let topGroup = {
        name: "置顶",
        color: "#3D87DA",
        key: "top",
        list: [],
      };
      let normalGroup = {
        name: "",
        key: "normal",
        list: [],
      };
      let completeGroup = {
        name: "已完成&已放弃",
        key: "complete",
        list: [],
      };

      this.data.forEach((element) => {
        // console.log(element.taskType == 2, element.endTime > 0, Date.now() - element.endTime > 12 * 60 * 60 * 1000)
        if (
          element.taskType == 2 &&
          element.endTime > 0 &&
          Date.now() - element.endTime > 12 * 60 * 60 * 1000
        )
          return;

        if (this.filtraData.checkTypeVal !== 1) {
          if (this.filtraData.checkTypeVal === 2) {
            if (element?.executors !== null) {
              if (!element.executors.includes(this.$store.state.user.userId)) {
                return false;
              }
            } else {
              return false;
            }
          }

          if (this.filtraData.checkTypeVal === 3) {
            // 类型过滤
            if (this.filtraData.type.length) {
              if (element?.taskType !== null) {
                if (!this.filtraData.type.includes(element.taskType)) {
                  return false;
                }
              } else {
                return false;
              }
            }

            // 清单过滤
            if (this.filtraData.todo.length) {
              if (element?.projectIdStr !== null) {
                if (!this.filtraData.todo.includes(element.projectIdStr)) {
                  return false;
                }
              } else {
                return false;
              }
            }

            // 时间过滤
            if (this.filtraData.date.length) {
              if (!element.startTime && !element.endTime) {
                return false;
              } else {
                this.filtraData.date[0] >= element.startTime &&
                  this.filtraData.date[1] <= element.endTime;
              }
            }

            // 标签过滤
            if (this.filtraData.tag.length) {
              if (element?.tagIdStrList) {
                if (
                  !this.filtraData.tag.some((item) => {
                    return element.tagIdStrList.includes(item);
                  })
                ) {
                  return false;
                }
              } else {
                return false;
              }
            }

            // 成员过滤
            if (this.filtraData.member.length) {
              if (element?.executors !== null) {
                if (
                  !this.filtraData.member.some((item) => {
                    return element.executors.includes(item);
                  })
                ) {
                  return false;
                }
              } else {
                return false;
              }
            }
          }
        }

        element.list = [];
        // element.expand = true;
        if (this.$route.params.task == element.taskIdStr) {
          this.$store.commit("taskChanged", element);
        }
        // console.log(element.completeTime, element.giveUp)
        // console.log(element.clockedHistoryDTOList)
        if (element.taskType == 2) {
          if (this.todayPercent(element) == 100) {
            if (this.collectionCompletedTask) {
              completeGroup.list.push(element);
            } else {
              normalGroup.list.push(element);
            }
          } else {
            normalGroup.list.push(element);
          }
        } else {
          if (element.completeTime > 0 || element.giveUp) {
            
            if (this.collectionCompletedTask) {
              completeGroup.list.push(element);
            } else {
              normalGroup.list.push(element);
            }
          } else {
            // console.log(element)
            normalGroup.list.push(element);
          }
        }
      });

      completeGroup.list = this.listToTree(completeGroup.list);
      normalGroup.list = this.listToTree(normalGroup.list);
      topGroup.list = this.searchTopTask(normalGroup.list);

      this.packageList = [""];
      if (topGroup.list.length > 0) {
        this.packageList.push(topGroup);
      } else {
        normalGroup.tile = true;
      }
      if (normalGroup.list.length > 0) {
        this.packageList.push(normalGroup);
      }
      // if (completeGroup.list.length > 0) {
      this.packageList.push(completeGroup);
      // }
      this.packageList.shift();
    },
    todayPercent(task) {
      if (task.taskType == 2 && task?.habitDTO?.habitDayNum) {
        let percent =
          (this.todayNum(task) * 100) / task?.habitDTO?.habitDayNum || 0;
        if (percent > 100) {
          percent = 100;
        }
        return percent;
      } else {
        return 0;
      }
    },
    todayNum(task) {
      const date = dayjs().format("YYYYMMDD");
      let array = [];
      let completeNum = 0;
      if (task.taskType == 2 && task.clockedHistoryDTOList) {
        array = task.clockedHistoryDTOList.filter((item) => {
          return dayjs(item.time).format("YYYYMMDD") == date;
        });
        completeNum = array.reduce((pre, item) => {
          return pre + item.completeNum;
        }, 0);
      }
      return completeNum;
    },
    /// 列表转树结构
    listToTree(list) {
      const obj = {};
      list.forEach((item) => {
        obj[item.taskIdStr] = item;
      });
      const parentList = [];
      list.forEach((item) => {
        const parent = obj[item.parentTaskIdStr];
        if (parent) {
          item.parentTaskName = parent.title;
          parent.list = parent.list || [];
          parent.list.push(item);
        } else {
          parentList.push(item);
        }
      });
      return parentList;
    },
    /// 查找置顶的任务
    searchTopTask(list) {
      let originList = [].concat(list);
      let topList = [];
      for (let i = 0; i < originList.length; i++) {
        const element = originList[i];
        if (element.isTop) {
          topList.push(element);
          let index = list.indexOf(element);
          list.splice(index, 1);
        } else if (element.list && element.list.length > 0) {
          topList = topList.concat(this.searchTopTask(element.list));
        }
      }
      return topList;
    },
    onSortClick(item) {
      this.sortId = item.id;
      localStorage.setItem(this.project.projectIdStr + "-" + "sortId", item.id);
      // this.$store.commit('updateTaskSortId', this.sortId);
    },
    onMoreClick(item) {
      // console.log(item)
      if (item.key === "subTask_hide" || item.key === "subTask_show") {
        this.subTaskMode = !this.subTaskMode;
        localStorage.setItem(
          this.project.projectIdStr + "-" + "subTaskMode",
          this.subTaskMode ? "1" : "0"
        );
        this.loadData();
      } else if (item.key === "done_hide" || item.key === "done_show") {
        this.detailMode = !this.detailMode;
        this.$store.commit("updateTaskDetailMode", this.detailMode);
        localStorage.setItem(
          this.project.projectIdStr + "-" + "detailMode",
          this.detailMode ? "1" : "0"
        );
      } else if (item.key === "eye_show" || item.key === "eye_hide") {
        this.completeMode = !this.completeMode;
        localStorage.setItem(
          this.project.projectIdStr + "-" + "completeMode",
          this.completeMode ? "1" : "0"
        );
      } else if (item.key === "filter") {
        console.log("");
      } else if (item.key === "folder") {
        this.showGroupModal = true;
      } else if (item.key === "map") {
        this.$router.push("/manager/group/map");
      } else if (item.key === "share") {
        console.log("");
      } else if (item.key === "trend") {
        console.log("");
      } else if (item.key === "filtra") {
        const projectId = this.$route.params.project;
        this.filtraData = JSON.parse(
          localStorage.getItem(projectId + "-" + "filtraData") ??
            '{"checkTypeVal":1,"type":[],"todo":[],"date":[],"tag":[],"member":[]}'
        );
        this.showFiltra = true;
      }

      // if (item.id == 1) {
      //   this.showGroupModal = true;
      // } else if (item.id == 4) {
      //   this.$Message.info('努力开发中~');
      // }
    },
    async onMenuClick(key) {
      // let task = Object.assign({}, this.editData);
      let task = this.editData;
      if (key == "top") {
        task.isTop = !task.isTop;
        this.updateTask(task);
      } else if (key == "date") {
        let attach = {};
        attach.startTime = this.editData.startTime;
        attach.endTime = this.editData.endTime;
        if (this.editData.hasNotice) {
          attach.notices = await noticeDetail(this.editData.taskIdStr);
          attach.noticeInfo = this.editData.noticeInfo;
        } else {
          attach.notices = [];
        }
        if (this.editData.hasRepeat) {
          attach.repeat = await repeatDetail(this.editData.taskIdStr);
        } else {
          attach.repeat = {};
        }
        if (this.editData.taskType == 2) {
          attach.habit = this.editData.habitDTO;
        }
        attach.attachType = this.editData.taskType;
        this.inputData.attach = attach;
        this.showModal = true;
      } else if (key.indexOf("flag_") > -1) {
        let levels = {
          flag_normal: 0,
          flag_low: 1,
          flag_middle: 2,
          flag_high: 3,
        };
        task.level = levels[key];
        this.updateTask(task);
      } else if (key == "task" || key == "subTask" || key == "Tab") {
        let isTab = key == "Tab";
        let nowTime = new Date().getTime();
        let subTask = {
          isTop: task.isTop,
          giveUp: task.giveUp,
          completeTime: task.completeTime > 0 ? nowTime : 0,
          title: "",
          projectIdStr: task.projectIdStr,
          parentTaskIdStr: isTab ? task.parentTaskIdStr : task.taskIdStr,
          taskType: 0,
          level: 0,
          startTime: task.startTime,
          endTime: task.endTime,
        };
        if (!isTab) task.expand = true;
        taskAdd(subTask).then((res) => {
          if (res) {
            this.$store.commit("updateFocusId", res);
            this.loadData();
            // if (key != 'subTask') this.$router.push('/manager/work/' + this.$route.params.project + '/' + res);
          }
        });
      } else if (key == "location") {
        this.showMap = true;
      } else if (key == "copy") {
        task = Object.assign({}, this.editData);
        task.taskIdStr = null;
        task.taskId = null;
        task.title = "「副本」" + task.title;
        taskAdd(task).then((res) => {
          if (res) {
            this.loadData();
          }
        });
      } else if (key == "type") {
        task.taskType = task.taskType == 1 ? 0 : 1;
        this.updateTask(task);
      } else if (key == "giveup") {
        if (task.taskType == 2) {
          task.endTime = task.endTime == 0 ? Date.now() : 0;
        } else {
          if (task.giveUp) {
            task.giveUp = false;
            task.completeTime = 0;
          } else {
            task.giveUp = true;
            task.completeTime = Date.now();
            if (!task.originTime) {
              task.originTime = task.startTime;
            }
          }
        }
        this.packageData();
        taskUpdate(task).then((res) => {
          if (res) this.loadData();
        });
      } else if (key == "delete") {
        taskDelete(task.taskIdStr).then((res) => {
          if (res) {
            this.$Message.success("删除成功，撤回暂时请前往APP操作");
            this.loadData();
            // console.log(task.taskIdStr, this.$route.params.task)
            if (task.taskIdStr == this.$route.params.task) {
              this.$router.push("/manager/work/" + this.$route.params.project);
            }
          }
        });
      }
    },
    // 菜单-标签选择框点击
    onTagClick(tag) {
      if (
        this.editData.tagList &&
        this.editData.tagList.indexOf(tag.tagIdStr) > -1
      ) {
        let index = this.editData.tagList.indexOf(tag.tagIdStr);
        this.editData.tagList.splice(index, 1);
      } else {
        let list = this.editData.tagList || [];
        list.push(tag.tagIdStr);
        this.editData.tagList = list;
      }
      this.updateTask(this.editData);
    },
    onMenuMove(project) {
      this.menuShow = false;
      this.editData.projectIdStr = project.projectIdStr;
      this.editData.projectId = project.projectId;
      this.editData.parentTaskId = null;
      this.editData.parentTaskIdStr = null;
      taskUpdate(this.editData).then((res) => {
        if (res) {
          this.loadData();
          this.$Message.success(
            `${this.editData.title} 已移动到 ${project.name}`
          );
          this.$store.commit("updateProjectCount", {
            key: "update",
            value: true,
          });
        }
      });
    },
    onGroupItemClick(group) {
      this.editData.groupIdStr = group.groupIdStr;
      this.updateTask(this.editData);
    },
    /// 更新任务
    updateTask(task) {
      this.packageData();
      taskUpdate(task);
    },
    onProjectClick(item) {
      this.$store.commit("projectChanged", item);
      this.$router.push("/manager/work/" + item.projectIdStr);
    },
    onInputFocus() {
      this.ifocus = true;
      if (Object.keys(this.editData).length > 0) {
        this.editData = {};
        this.inputData.tag = [];
      }
      // if (!this.inputData.attach.startTime) {
      //   this.inputData.attach.startTime = new Date().setHours(0,0,0,0);
      // }
    },
    async onInputChange() {
      // console.log(this.inputData.input)
      const res = await timeRecogntion(this.inputData.input);
      // console.log(res.timeText)

      let regex = new RegExp(res.timeText, "g");
      this.formattedText = this.inputData.input.replace(regex, (match) => {
        return `<span style="background: #ffff008c;padding: 3px 0px;border-radius: 4px;">${match}</span>`;
      });
      this.formattedRes = res.timeText;
      if (res.startTime) {
        this.inputData.attach.startTime = res.startTime;
      }
      if (res.endTime) {
        this.inputData.attach.endTime = res.endTime;
      }
    },
    async onInputKeydown(e) {
      if (e.key == "Enter") {
        e.preventDefault();
      }
      if (e.key == "Tab") {
        e.preventDefault();
        let taskId = await this.onInputEnter();
        this.$router.push(
          "/manager/work/" + this.$route.params.project + "/" + taskId
        );
        if (window.editor) {
          window.editor.focus();
        } else {
          setTimeout(() => {
            if (window.editor) window.editor.focus();
          }, 150);
        }
      }
    },
    /// 新增任务
    async onInputEnter() {
      let projectIdStr = this.project.projectIdStr;
      if (this.inputData.project.projectIdStr) {
        projectIdStr = this.inputData.project.projectIdStr;
      } else if (this.isAllProject || this.isDateProject) {
        projectIdStr = "1" + this.$store.state.user.userId;
      }
      let positionWeight = 10;
      this.packageList.forEach((item) => {
        if (item.key == "normal") {
          if (item.list && item.list.length > 0) {
            positionWeight = item.list[0].positionWeight + 10;
          }
          return;
        }
      });
      // 处理顶部输入框自动换行导致回车\n一并提交问题
      if (
        this.inputData.input.lastIndexOf("\n") ==
        this.inputData.input.length - 1
      ) {
        this.inputData.input = this.inputData.input.substring(
          0,
          this.inputData.input.length - 1
        );
      }
      let title = "";
      if (this.formattedRes === this.inputData.input) {
        title = this.inputData.input;
      } else {
        title = this.inputData.input.replaceAll(this.formattedRes, "");
      }

      let task = {
        giveUp: false,
        completeTime: 0,
        title: title,
        level: this.inputData.level.level,
        projectIdStr,
        positionWeight,
        taskType: this.inputData.type,
        startTime: this.inputData.attach.startTime,
        endTime: this.inputData.attach.endTime || 0,
        tagList: this.inputData.tag.map((item) => item.tagIdStr),
        groupId: this.inputData.group.groupIdStr,
        noticeInfo: this.inputData.attach.noticeInfo,
      };
      this.addLoading = 80;
      let taskId = await taskAdd(task, true);
      this.addLoading = 100;
      setTimeout(() => {
        this.addLoading = 0;
      }, 300);
      if (taskId) {
        if (task.taskType == 2) {
          const habit = this.inputData.attach.habit;
          habit.updateTime = new Date().getTime();
          if (habit && Object.keys(habit).length > 0) {
            habit.taskIdStr = taskId;
            habit.taskId = parseInt(taskId, 10);
            await habitAdd(habit);
          }
        }

        if (
          this.inputData.attach.notices &&
          this.inputData.attach.notices.length > 0
        ) {
          for (let i = 0; i < this.inputData.attach.notices.length; i++) {
            const notice = this.inputData.attach.notices[i];
            notice.taskIdStr = taskId;
            notice.taskId = parseInt(taskId, 10);
            await noticeAdd(notice);
          }
        }
        let repeat = this.inputData.attach.repeat;
        if (repeat && Object.keys(repeat).length > 0) {
          repeat.taskIdStr = taskId;
          repeat.taskId = parseInt(taskId, 10);
          await repeatAdd(repeat);
        }
        await syncData();
        this.loadData();
        this.editGroupList = [];
        this.onAttachClear();
      }
      return taskId;
    },
    // onDetailModeChange(value) {
    //   this.$store.commit('updateTaskDetailMode', value);
    //   localStorage.setItem(this.project.projectIdStr + '-' + 'detailMode', value ? '1' : '0');
    // },
    // onCompleteModeChange(value) {
    //   // this.$store.commit('updateTaskCompleteMode', value);
    //   localStorage.setItem(this.project.projectIdStr + '-' + 'completeMode', value ? '1' : '0');
    // },
    showTaskMenu(event, data) {
      this.editData = data;

      this.menuOptions.x = event.x + "px";
      this.menuOptions.y = event.y + "px";

      this.$refs.menu.currentVisible = false;
      this.$nextTick(() => {
        this.$refs.menu.currentVisible = true;
      });
    },
    onAttachClick(e) {
      e.preventDefault();
      this.showModal = true;
    },
    onAttachClear(e) {
      if (e) {
        e.preventDefault();
      }
      this.formattedRes = "";
      this, (this.formattedText = "");
      this.inputData.project.selected = false;
      this.inputData = {
        type: 0,
        input: "",
        attach: {},
        level: levelList[3],
        tag: [],
        project: {},
        group: {},
        startTime: "",
        endTime: "",
      };
      this.setStartTime();
    },
    onLevelClick(e) {
      e.preventDefault();
    },
    onLevelSelected(e, item) {
      e.preventDefault();
      this.inputData.level = item;
    },
    onInputTagClick(e) {
      e.preventDefault();
    },
    onInputProjectClick(e) {
      e.preventDefault();
      this.showPoptip = true;
    },
    onGroupClick(group) {
      this.inputData.group = group;
      this.showPoptip = false;
    },
    onTreeSelect(value) {
      if (value.length > 0) {
        this.inputData.project = value[0];
        groupList(this.inputData.project.projectIdStr).then((res) => {
          if (res) {
            this.editGroupList = res.sort(
              (a, b) => b.positionWeight - a.positionWeight
            );
          }
        });
      }
    },
    onTaskTreeSelect(value) {
      this.$refs.menu.currentVisible = false;
      if (value.length > 0) {
        this.onMenuMove(value[0]);
      }
    },
    onModalChanged(value) {
      this.showModal = value;
      if (!this.editData.taskIdStr) {
        this.$refs.input.focus();
      }
    },
    async onModalOk(attach) {
      if (Object.keys(this.editData).length > 0) {
        this.editData.startTime = attach.startTime;
        this.editData.originTime = attach.startTime;
        this.editData.endTime = attach.endTime;
        this.editData.noticeInfo = attach.noticeInfo;
        this.editData.hasNotice = attach.notices.length > 0;

        let addList = [];
        let deleteList = [];
        this.inputData.attach.notices.forEach((item) => {
          let hasSome = attach.notices.some(
            (subItem) =>
              item.mode == subItem.mode &&
              item.day == subItem.day &&
              item.hour == subItem.hour &&
              item.minute == subItem.minute
          );
          if (!hasSome) {
            deleteList.push(item);
          }
        });
        attach.notices.forEach((item) => {
          let hasSome = this.inputData.attach.notices.some(
            (subItem) =>
              item.mode == subItem.mode &&
              item.day == subItem.day &&
              item.hour == subItem.hour &&
              item.minute == subItem.minute
          );
          if (!hasSome) {
            addList.push(item);
          }
        });
        for (let i = 0; i < addList.length; i++) {
          const notice = addList[i];
          notice.taskIdStr = this.editData.taskIdStr;
          notice.taskId = parseInt(this.editData.taskIdStr, 10);

          await noticeAdd(notice);
        }
        for (let i = 0; i < deleteList.length; i++) {
          const notice = deleteList[i];

          await noticeDelete(notice.taskNoticeIdStr);
        }

        // if (attach.notices && attach.notices.length > 0) {
        //   for (let i = 0; i < attach.notices.length; i++) {
        //     const notice = attach.notices[i];
        //     notice.taskIdStr = this.editData.taskIdStr;
        //     await noticeAdd(notice);
        //   }
        // }
        let repeat = attach.repeat;
        if (repeat && Object.keys(repeat).length > 0) {
          repeat.taskIdStr = this.editData.taskIdStr;
          repeat.taskId = parseInt(this.editData.taskIdStr, 10);
          await repeatAdd(repeat);
        } else if (
          this.inputData.attach.repeat &&
          Object.keys(this.inputData.attach.repeat).length > 0
        ) {
          await repeatDelete(this.editData.taskIdStr);
        }
        await taskUpdate(this.editData, true);
        await noticeArrive();
        this.loadData();
      } else {
        this.inputData.attach = attach;
      }
    },
    onModalCancel() {
      if (Object.keys(this.editData).length > 0) {
        this.inputData.attach = {};
      }
    },
    onFiltraModalOk() {
      this.filtraData = this.filtraData = this.$refs.filterRef.filter;
      localStorage.setItem(
        this.project.projectIdStr + "-" + "filtraData",
        JSON.stringify(this.filtraData)
      );
      this.packageData();
    },
    onGroupModalOk() {
      if (this.groupInput.length > 0) {
        let positionWeight = 10;
        if (this.groupList.length > 0) {
          positionWeight = this.groupList[0].positionWeight + 10;
        }
        groupAdd({
          projectIdStr: this.project.projectIdStr,
          positionWeight,
          name: this.groupInput,
        }).then(() => {
          localStorage.setItem(this.project.projectIdStr + "-" + "sortId", 1);
          this.loadData();
        });
      }
    },
    /// 输入框标签点击
    onTagBoxClick(item) {
      let index = this.inputData.tag.indexOf(item);
      if (index > -1) {
        this.inputData.tag.splice(index, 1);
      } else {
        this.inputData.tag.push(item);
      }
      if (Object.keys(this.editData).length > 0) {
        this.editData.tagList = this.inputData.tag.map((tag) => tag.tagIdStr);
        this.updateTask(this.editData);
      }
    },
    onMapSelected(value) {
      this.location = value;
    },
    onMapModalOk(location) {
      if (location) {
        this.editData.addressIdStr = location.addressIdStr;
        this.editData.addressId = location.addressId;
      } else {
        this.editData.addressIdStr = null;
        this.editData.addressId = null;
      }
      taskUpdate(this.editData);
    },
    setStartTime() {
      if (this.project.projectType == 1) {
        if (this.project.name == "明天") {
          let date = new Date().setHours(0, 0, 0, 0);
          this.inputData.attach.startTime = date + 1000 * 60 * 60 * 24;
        } else {
          this.inputData.attach.startTime = new Date().setHours(0, 0, 0, 0);
        }
      }
    },
    showGroupMenu(event, data) {
      this.groupMenuShow = true;
      this.groupMenuOptions.x = event.x;
      this.groupMenuOptions.y = event.y;
      this.groupEditData = data;
      this.groupMoveModel = 0;
      this.groupEditInput = data.name;
    },
    onGroupMenuClick(key) {
      if (key == "edit") {
        this.showGroupEditModal = true;
      } else if (key == "move") {
        this.showGroupMoveModal = true;
      } else if (key == "sort") {
        this.groupSortList = [...this.groupList];
        this.showGroupSortModal = true;
      } else if (key == "delete") {
        this.showGroupDeleteModal = true;
      }
    },
    onGroupEditModalOk() {
      if (this.groupEditInput.length > 0) {
        this.groupEditData.name = this.groupEditInput;
        groupUpdate(this.groupEditData).then((res) => {
          if (res) {
            this.loadData();
          }
        });
      }
    },
    async onGroupMoveModalOk() {
      if (this.groupMoveModel > 0) {
        for (let i = 0; i < this.groupEditData.list.length; i++) {
          const element = this.groupEditData.list[i];
          element.groupIdStr = this.groupMoveModel;
          element.groupId = Number(this.groupMoveModel);
          await taskUpdate(element);
        }
        this.loadData();
      }
    },
    async onGroupSortModalOk() {
      if (this.groupSortList.length > 0) {
        for (let i = 0; i < this.groupSortList.length; i++) {
          const element = this.groupSortList[i];
          element.positionWeight = (this.groupSortList.length - i) * 10;
          await groupUpdate(element);
        }
        this.loadData();
      }
    },
    async onGroupDeleteModalOk() {
      if (this.groupMoveModel > 0) {
        for (let i = 0; i < this.groupEditData.list.length; i++) {
          const element = this.groupEditData.list[i];
          element.groupIdStr = this.groupMoveModel;
          element.groupId = Number(this.groupMoveModel);
          await taskUpdate(element);
        }
        await groupDelete(this.groupEditData.groupIdStr);
        this.loadData();
      }
    },
    findNodeByTree(pItem, sTask) {
      pItem.list?.forEach((item) => {
        if (item.taskId == sTask.parentTaskId) {
          item.list.push(sTask);
        } else {
          this.findNodeByTree(item, sTask);
        }
      });
    },
  },
  watch: {
    $route(n, o) {
      if (!n.params.task) {
        // this.packageList = []
      }
      this.loadTask = true;
      if (o.params.project != n.params.project || n.params.task.length == 0) {
        this.onAttachClear();
        this.loadData();
      }
      this.loadTask = false;
      this.setStartTime();
    },
    async listFlag(n) {
      if (n.key == "update") {
        let task = n.value || this.currentTask;
        const res = await taskUpdate(task, true);
        if (res) {
          this.$store.commit("updateProjectCount", {
            key: "update",
            value: true,
          });
          this.loadData();
          if (!task.giveUp && task.completeTime > 0) {
            this.$Message.success("恭喜，任务已完成");
          }
        }
      } else if (n.key == "delete") {
        taskDelete(n.value.taskIdStr).then((res) => {
          if (res) {
            this.loadData();
          }
        });
      } else if (n.key == "load") {
        this.loadData();
      } else if (n.key == "package") {
        this.packageData();
      } else if (n.key == "address") {
        this.editData = this.currentTask;
        this.showMap = true;
      } else if (n.key == "subTask") {
        this.editData = this.currentTask;
        this.onMenuClick("subTask");
      }
    },
    "$store.state.menuData"(value) {
      if (value.event && value.task) {
        this.detailMenu = true;
        this.showTaskMenu(value.event, value.task);
      }
    },
  },
};
</script>

<style lang="less" scoped>
:deep(.ivu-input) {
  background-color: var(--vp-c-bg-soft);
}
.drag-group-sort {
  background-color: var(--vp-c-bg-soft);
  color: var(--vp-c-text-1);
  display: flex;
  padding: 10px 0px;
  align-items: center;
  cursor: move;
}
.drag-group-sort-title {
  font-size: 12px;
  color: var(--vp-c-text-1);
  margin-bottom: 12px;
}
.move-list {
  white-space: normal;
}
.task {
  background-color: var(--vp-c-bg);
  color: var(--vp-c-text-1);
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 21px;
  .menu {
    position: fixed;
    z-index: 999;
    width: 168px;
  }
  .task-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 32px 5px 32px;
    .project-name {
      font-size: 18px;
      color: var(--vp-c-text-1);
      font-weight: 500;
      margin-left: 8px;
    }
    .rotate {
      animation: rotate 1s linear infinite;
    }

    @keyframes rotate {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
  .input-bg {
    border: 1px dashed transparent;
    margin: 10px 30px;
    padding: 5px 0px;
    border-radius: 8px;
    background-color: var(--vp-c-bg);
    position: relative;
    :deep(.ivu-progress) {
      width: calc(100% - 47px);
      font-size: 12px;
      position: absolute;
      bottom: 30px;
      float: right;
      right: 14px;
    }
    :deep(.ivu-progress-inner) {
      background-color: #ffffff00;
    }
    .title-mask {
      position: absolute;
      left: 30px;
      vertical-align: bottom;
      font-size: 14.5px;
      color: #ff000000;
      pointer-events: none;
      .mask {
        background: #ffff0069;
      }
    }
    &:hover {
      border-color: #1b9aee;
    }
    &.focus {
      border-color: #1b9aee;
      background-color: var(--vp-c-bg);
      box-shadow: 0px 1px 12px 0px #00000011;
      color: var(--vp-c-text-1);
    }
    .tag-button {
      background-color: var(--vp-c-bg-alt);
      border: none;
      color: var(--vp-c-text-1);
    }
    :deep(.ivu-input) {
      resize: none;
      margin: 0 5px;
      width: calc(100% - 10px);
    }
  }
  .sub-title {
    margin: 0px 32px;
    font-size: 14px;
    color: var(--vp-c-text-2);
    font-weight: 400;
  }
  // :deep(.ivu-input-wrapper) {
  // margin: 12px 32px;
  // width: calc(100% - 64px);
  // }
  :deep(.ivu-input) {
    background-color: var(--vp-c-bg-soft);
    height: 30px;
    border-radius: 8px;
  }
  .focus {
    :deep(.ivu-input) {
      background-color: var(--vp-c-bg-alt);
      color: var(--vp-c-text-1);
    }
  }
  .task-content {
    flex: 1;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    .content-empty {
      margin: 0 auto;
      padding-top: 100px;
      text-align: center;
      p.title {
        margin: 10px 0px 7px 0px;
        font-size: 14px;
        font-weight: 400;
      }
      p.sub-title {
        font-size: 12px;
        font-weight: 400;
        color: var(--vp-c-text-2);
      }
    }
  }
}
</style>
